import { getSetting } from './config';
import { init, track, trackPages } from "insights-js";

export function logPageView() {
  // no action needed, insights does the tracking automatically.
}

export function trackStartReading(issue) {
  trackEvent('Reading', 'Scrolled To Content', issue);
}

export function trackReadToHalf(issue) {
  trackEvent('Reading', 'Scrolled To Half', issue);
}

export function trackReadToEnd(issue) {
  trackEvent('Reading','Scrolled To End', issue)
}

export function trackEvent(category, action, label) {
  track({
    id: action,
    parameters: {
      category, label
    }
  })
}

export function initAnalytics() {
  init(getSetting('insights_project_key'));
  trackPages();
}