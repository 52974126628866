import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Graph from "./Graph/Graph";
import DirectGraph from "./Graph/DirectGraph";

/**
 * Recursive function for finding links in content.
 * The function will only return internal absolute or relative links
 */
function getLinks(links, content) {
  if (content.children) {
    for (let i = 0; i < content.children.length; i++) {
      getLinks(links, content.children[i]);
    }
  }
  let linkTarget = "";
  if (content.tagName === "a" && content.properties.href) {
    if (content.properties.href.startsWith("/")) {
      linkTarget = content.properties.href;
    }
    // if there are any absolute links in the content (god forbid),
    // transform them into relative ones, or the nodes will not be found
    // (nodes are built with their frontmatter path info, which is always relative)
    if (content.properties.href.startsWith("https://www.fwends.net/")) {
      linkTarget = content.properties.href.substring(22);
    }

    // remove any anchors
    if (linkTarget.includes("#")) {
      linkTarget = linkTarget.split("#")[0];
    }
    //normalise against a trailing '/'
    if (!linkTarget.endsWith("/")) {
      linkTarget = linkTarget + "/";
    }
    //don't add links to root or the chronological list
    if (
      linkTarget !== "" &&
      linkTarget !== "/articles/list" &&
      linkTarget !== "/"
    ) {
      links.push(linkTarget);
    }
  }
  return links;
}

/**
 * Wrapper for a graph view implementation that is responsible for preparing the data
 * and then call the implementation.
 *
 * @param {*} props
 * @returns
 */
export default function ArticleGraph(props) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          frontmatter: { path: { regex: "/articles/" }, date: {}, lang: {} }
        }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            htmlAst
            frontmatter {
              date
              path
              featureImage
              title
              abstract
            }
          }
        }
      }
    }
  `);

  let graphData = { edges: [], nodes: [] };
  let { active } = props;
  if (!active.endsWith('/')) {
    active += '/';
  }
  // go through the data and add all edges to the nodes array 
  for (let i = 0; i < data.allMarkdownRemark.edges.length; i++) {
    let id = data.allMarkdownRemark.edges[i].node.frontmatter.path;
    // normalise any paths
    if (!id.endsWith("/")) {
      id = id + "/";
    }
    graphData.nodes.push({
      id: id,
      label: data.allMarkdownRemark.edges[i].node.frontmatter.title,
    });
    // collect all outgoing links of the current node, and add them to
    // the edges array.
    let allLinks = [];
    getLinks(allLinks, data.allMarkdownRemark.edges[i].node.htmlAst);
    allLinks.map((link) => {
      graphData.edges.push({
        source: id,
        target: link,
      });
    });
  }

  // iterate through all link targets and make sure that they exist as nodes
  // if an article links to some other place on this domain (for example, a
  // Let's be Fwends edition), we will have the link, but no corresponding node.
  // Here we can rectify this situation.
  for (let i = 0; i < graphData.edges.length; i++) {
    if (
      graphData.nodes.filter((node) => {
        return node.id === graphData.edges[i].target;
      }).length === 0
    ) {
      // We have no title for the link, so instead we use the target URL as
      // label
      graphData.nodes.push({
        id: graphData.edges[i].target,
        label: graphData.edges[i].target,
        nonarticle: true,
      });
    }
  }


  return <DirectGraph data={graphData} active={active} repulsion={-250}/>;
}
