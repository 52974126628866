import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DirectGraph from "./Graph/DirectGraph";

/**
 * 
 * todo we have to get the whole array of all articles (or maybe in this case even the whole content set)
 * because we also want to show pages that link TO this page.
 * so we get the same stuff as articlegraph, and then when getlinks is working, we just check if
 * source or target are equal to the current path. ("active" prop).
 * that should give us all the links and all nodes involved.
 * then we iterate through that array and add nodes as needed.
 * 
 */


/**
 * Recursive function for finding links in content.
 * The function will only return internal absolute or relative links
 */
function getLinks(links, content) {
  if (content.children) {
    for (let i = 0; i < content.children.length; i++) {
      getLinks(links, content.children[i]);
    }
  }
  let linkTarget = "";
  if (content.tagName === "a" && content.properties.href) {
    if (content.properties.href.startsWith("/")) {
      linkTarget = content.properties.href;
    }
    // if there are any absolute links in the content (god forbid),
    // transform them into relative ones, or the nodes will not be found
    // (nodes are built with their frontmatter path info, which is always relative)
    if (content.properties.href.startsWith("https://www.fwends.net/")) {
      linkTarget = content.properties.href.substring(22);
    }

    // remove any anchors
    if (linkTarget.includes("#")) {
      linkTarget = linkTarget.split("#")[0];
    }
    //normalise against a trailing '/'
    if (!linkTarget.endsWith("/")) {
      linkTarget = linkTarget + "/";
    }
    //don't add links to root or the chronological list
    if (
      linkTarget !== "" &&
      linkTarget !== "/articles/list" &&
      linkTarget !== "/"
    ) {
      links.push(linkTarget);
    }
  }
  return links;
}

/**
 * Wrapper for a graph view implementation that is responsible for preparing the data
 * and then call the implementation.
 * 
 * This implementation shows only nodes that are directly connected to the currently active
 * page (the paging is linking to the other page, or the other page is linking to this page)
 *
 * @param {*} props
 * @returns
 */
export default function LocalGraph(props) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: {
          frontmatter: { path: {}, date: {}, lang: {} }
        }
      ) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            htmlAst
            frontmatter {
              date
              path
              featureImage
              title
              abstract
            }
          }
        }
      }
    }
  `);

  let graphData = { edges: [], nodes: [] };
  let { active } = props;
  if (!active.endsWith('/')) {
    active += '/';
  }
  
  // go through the data and add all edges to the nodes array 
  for (let i = 0; i < data.allMarkdownRemark.edges.length; i++) {
    
    let id = data.allMarkdownRemark.edges[i].node.frontmatter.path;
    
    // normalise any paths
    if (!id.endsWith("/")) {
      id = id + "/";
    }

    let allLinks = [];
    getLinks(allLinks, data.allMarkdownRemark.edges[i].node.htmlAst);

    allLinks.map((link) => {
      graphData.edges.push({
        source: id,
        target: link,
      });
    });
  }
  
  let networkLinks = graphData.edges.slice();

  //filter out all links that are not related to our active node
  graphData.edges = graphData.edges.filter((edge) => {
    return normalisePath(edge.source) === normalisePath(active) || normalisePath(edge.target) === normalisePath(active);
  });
  
  // get a list of unique node values (set only accepts unique values)
  const temp = new Set();
  graphData.edges.forEach(({source, target}) => {
    temp.add(source);
    temp.add(target);
  });
  
  // and add them to the graph data object. We also find the correct title for it.
  graphData.nodes = Array.from(temp).map((value) => {
    return {
      id: value,
      label: findPageTitle(data, value),
      nonarticle: !value.startsWith('/articles/')
    }});
  
    // here, we need to re-run the exercise for all new nodes; we need to add
    // the links between them if there are any.
    // we take graphData.nodes and iterate over it. for each, we see if
    // allLinks contains a link that does not involve active, and if there is,
    // we add the link to the graphData.edges array.
    let remote_links = [];
    for (let i =0; i < graphData.nodes.length; i++)
    {
      if (graphData.nodes[i].id !== active) {
        for (let j=0; j < networkLinks.length; j++) {
          if (isRemoteLink(graphData.nodes[i].id, graphData.nodes, networkLinks[j], active)) {
            remote_links.push(networkLinks[j]);
          }
        }
      }

    }
    graphData.edges = graphData.edges.concat(remote_links);

  return <DirectGraph data={graphData} active={active} repulsion={-8000}/>;
}

/**
 * Checks if a link is a remote link.
 * This is the case if link source (id) and link target are both in the set of nodes, and 
 * the target is not the active node (in which case the link is not remote in the sense of 
 * connecting two nodes with each other that are both one step from the active node).
 * 
 * @param {*} id 
 * @param {*} nodes 
 * @param {*} link 
 * @param {*} active 
 * @returns 
 */
function isRemoteLink(id, nodes, link, active) {
  return (link.source === id && link.target !== active && nodes.filter((node) => {return node.id === link.target})).length > 0;
}


function findPageTitle(data, path) {
  let title = path;

  let node = data.allMarkdownRemark.edges.filter(({node}) => {
    return normalisePath(node.frontmatter.path) === normalisePath(path);
  });

  if (node.length > 0) {
    title = node[0].node.frontmatter.title;
  }
  return title;

}

function normalisePath(path) {
  if (path.charAt(path.length - 1) !== '/') {
    return path + '/';
  }
  return path;
}