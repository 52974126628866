/**
 * Helper Utility for keeping all configurable settings in one place.
 * 
 * Allows to swap out configuration settings depending on the environment.
 */

export const ENV_STATIC = 'static';
export const ENV_DEV = 'dev';
export const ENV_PRODUCTION ='production';


//per default, we use a dev environment.
var environment = ENV_DEV;


export function setEnvironment(newEnv) {
  environment = newEnv;
}

var config = {
  [ENV_STATIC]: {
    'ga_debug': true,
    'insights_project_key':'SUmwV9QV4BEgOER7'
  },
  [ENV_DEV]: {
    'newsletter_subscription_url': 'https://fwends.us15.list-manage.com/subscribe/post-json?u=80228c1d253c812406da446f5&id=94ce69120c&EMAIL=',
    'insights_project_key':'SUmwV9QV4BEgOER7'
  },
  [ENV_PRODUCTION]: {
    'newsletter_subscription_url': 'https://fwends.us15.list-manage.com/subscribe/post-json?u=80228c1d253c812406da446f5&id=94ce69120c&EMAIL=',
    'insights_project_key':'SUmwV9QV4BEgOER7'

  }
}

export function getSetting(key) {
  return config[environment][key];
}

export function getEnvironment() {
  return environment;
}

export function isStatic() {
  return environment === ENV_STATIC;
}